import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import StepContext from 'contexts/StepContext';
import GlobalContext from 'contexts/GlobalContext';
import { ReactComponent as Warning } from 'images/warningBlue.svg';
import { ReactComponent as CheckWithCircle } from '../images/check-with-circle-blue.svg';
import { typeMapping } from 'constants/steps';

const ResultSuccess = () => {
  const { type = 'pay' } = useParams();
  const { setCurrentStep } = useContext(StepContext);
  const { parkingInformation, billDetail } = useContext(GlobalContext);
  const paying_note = !parkingInformation.after_paying_page_note ?
    localStorage.getItem('paying_note') : parkingInformation.after_paying_page_note;
  const lot_exit_time = !billDetail.lot_allow_exit_time ?
    localStorage.getItem('exit_time') : billDetail.lot_allow_exit_time;
  const exit_time = lot_exit_time?.substring(0, 16);
  const stepList = typeMapping[type];

  useEffect(() => {
    setCurrentStep(stepList[stepList.length - 1].step);
  }, [stepList, setCurrentStep]);

  return <div className="p-4 pt-[100px] text-center h-[calc(100vh-132px)]">

    <CheckWithCircle className='w-[40px] h-[40px] mx-auto mb-6' />
    <p>繳費成功</p>
    {
      exit_time && type === 'pay' ?
        <p className='mt-4 pb-40'>
          請於<span className='text-red-500 mx-1'>{exit_time}</span>之前離場，<br />
          憑證已寄至您填寫的電子信箱，<br />謝謝您的配合！
        </p>
        :
        <p className='mt-1 pb-20'>
          憑證已寄至您填寫的電子信箱！
        </p>
    }

    {
      paying_note &&
      <div className='flex justify-center items-center mx-auto pb-40'>
        <Warning width="18px" height="18px" />
        <pre className='text-left  ml-1 text-xs' dangerouslySetInnerHTML={{ __html: paying_note as string }} />
      </div>
    }
  </div >
}
export default ResultSuccess;