import { useEffect, useContext, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-toastify';

import { GlobalContext } from 'contexts/GlobalContext';
import Button from 'components/Button'
import directCardSetup from 'utils/directCardSetup';
import { confirmPaymentAPI } from 'utils/fetchAPI';
import { GetPrime } from "types";
import { ReactComponent as DirectPayIcon } from '../images/direct-pay.svg'
interface Prop {
  holderInfo: FieldValues,
  paymentMethod: string,
  reuseCheck:boolean
  goToBack: () => void,
}

type OnTPDirectUpdate = (args: {
  cardType: string,
  canGetPrime: boolean,
  hasError: boolean,
  status: {
    number: number,
    expiry: number,
    ccv: number,
  }
}) => void

const PaymentCardInput = ({
  paymentMethod,
  holderInfo,
  reuseCheck,
  goToBack
}: Prop) => {
  const { billDetail, setIsLoading } = useContext(GlobalContext);
  const [canGetPrime, setCanGetPrime] = useState(false);
  const [prime, setPrime] = useState('');

  useEffect(() => {
    directCardSetup();

    window.TPDirect.card.onUpdate(
      function (update) {
        setCanGetPrime(update.canGetPrime);
      } as OnTPDirectUpdate);
  }, []);

  useEffect(() => {
    if (!canGetPrime) {
      setPrime('');
      return;
    }

    window.TPDirect.card.getPrime(
      function (result) {
        if (result.status !== 0) {
          toast.error('get prime error ' + result.msg);
          return;
        }

        setPrime(result.card.prime);

        // send prime to your server, to pay with Pay by Prime API .
        // Pay By Prime Docs: https://docs.tappaysdk.com/tutorial/zh/back.html#pay-by-prime-api
      } as GetPrime
    )
  }, [canGetPrime]);

  const handlePayment = async () => {

    setIsLoading(true);
    const {
      phone_number,
      name,
      email,
      national_id,
      zip_code = '',
      address = '',
      carrier_id = '',
      donate_id = '',
      tax_id = '',

    } = holderInfo;

    const {
      bill_id = '',
      bill_token = ''
    } = billDetail;

    let paymentDetailTemp = {
      prime,
      "cardholder": {
        phone_number,
        name,
        email,
        zip_code,
        address,
        national_id
      },
      bill_id,
      payment_method: paymentMethod,
      bill_token,
      carrier_id,
      donate_id,
      tax_id,
      is_reuse:reuseCheck
    };

    try {
      const response = await confirmPaymentAPI(paymentDetailTemp);
      const result = await response.json();
      if (result?.payment_url) {
        // window.location.href = result.payment_url;
        window.location.replace(result.payment_url);
        sessionStorage.setItem('payment_url', result.payment_url);
      }
    } catch (err) {
      toast.error('付款發生錯誤');
      setIsLoading(false);
    }
  }

  return <>
    <div className='pt-[50px] mb-15 text-center'>
      <h5 className='text-center mb-[30px]'>請填寫信用卡資料</h5>

      <div className='inline-flex items-center text-xs text-gray-label mb-6'>
        <span className='mr-1'> 支援的信用卡別</span> <DirectPayIcon />
      </div>

      <div className='flex items-center mb-7'>
        <span className='text-xs text-gray-label mr-1'>信用卡卡號：</span>
        <div className="tpfield w-[171px] h-[25px] border-b border-gray-label" id="card-number"></div>
      </div>


      <div className='flex items-center mb-7'>
        <span className='text-xs text-gray-label mr-1'>卡片到期日：</span>
        <div className="tpfield w-[171px] h-[25px] border-b border-gray-label" id="card-expiration-date"></div>
      </div>

      <div className='flex items-center mb-7'>
        <span className='text-xs text-gray-label mr-1'>卡片後三碼：</span>
        <div className="tpfield w-[171px] h-[25px] border-b border-gray-label" id="card-ccv"></div>
      </div>

    </div>

    <p className='mb-[14px] text-center text-error text-[10px] '>
      點選下一步將進入信用卡3D驗證畫面，請留意發卡銀行的驗證簡訊， <br />
      收到後請填入正確的驗證碼，若輸入錯誤3次將會被鎖卡，還請留意！
    </p>
    <div className='relative lg:w-3/12 w-4/5 md:w-6/12 sm:w-2/4 ml-auto mr-auto mt-4'>
      <Button type="submit" className="mx-auto block" title="下一步"
        disabled={!holderInfo?.name || !prime}
        onClick={handlePayment}
      />
      <div className='md:max-w-[658px] max-w-[320px]  mx-auto absolute top-1/2 left-0' style={{ transform: "translateY(-50%)" }}>
        <i className="fa-solid fa-chevron-left cursor-pointer text-primary" onClick={goToBack} ></i>

      </div>
    </div>



  </>;
}
export default PaymentCardInput;